<template>
  <div class="after-you-apply">
    <HeaderNav />
    <main id="content" role="main" tabindex="-1">
      <div class="container-fluid after_apply_banner" role="presentation"></div>

      <!--START CONTAINER ICONS-->
      <div class="container-md py-5">
        <div class="row">
          <div class="col-12">
            <h1 id="main-title" class="innerpage my-5">
              What happens after I submit my grant application?
            </h1>
            <h3>
              Thank you for applying for a Merck U.S. Patient Advocacy (USPA)
              Grant! Learn what happens after you hit “submit” on your completed
              grant application.
            </h3>
            <h3>After you submit the application:</h3>
            <ul>
              <li>
                You will get a confirmation email from
                <strong>MSD Grant Portal</strong> (<a target="_blank"
                  class="content-link"
                  href="mailto:noreply@system.versaic.com"
                  >noreply@system.versaic.com</a
                >)
              </li>
              <li>
                You will get important emails from this address – please add
                <a class="content-link" target="_blank" href="mailto:noreply@system.versaic.com"
                  >noreply@system.versaic.com</a
                >
                to your address book or safe sender list so all future emails
                get to your inbox
              </li>
            </ul>
          </div>
        </div>
        <div class="row px-3 mt-5">
          <div class="col-2 icon-col">
            <div class="icon-bx d-md-flex">
              <img
                alt=""
                role="presentation"
                src="@/assets/images/pen-check-icon.png"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">If we are reviewing your application</p>
            <ul>
              <li>
                If we ask you for more information, please submit it right away
                because we will need it to reach a decision about your grant
                request
              </li>
              <li class="pt-4">
                You can log in to the <strong>MSD Grant Portal</strong> (<a
                  class="content-link interstitial-link"
                  data-bs-toggle="modal"
                  data-bs-target="#interstitial-modal"
                  href="http://www.msdgrant.com"
                  target="_blank"
                  >www.msdgrant.com</a
                >) to view your request(s) and check the status of your
                application. Once you’ve logged in, you will see a list of all
                your requests. To the right of each request, you will see the
                status
              </li>
            </ul>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <div class="icon-bx d-md-flex">
              <img
                alt=""
                role="presentation"
                src="@/assets/images/support-icon.png"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">If we decide to support your request</p>
            <ul class="minus-plus-bullets">
              <li>
                If a decision is made to support your request, you will get a
                Letter of Agreement (LOA) by email from DocuSign. It must be
                signed by your organization’s authorized signer, as designated
                in your application,
                <strong>before the start date of your program or event</strong>.
                <ul class="pt-4">
                  <li>
                    If we are unable to support your request, we will email you
                    that your request has been declined. All decisions are
                    final.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <div class="icon-bx d-md-flex">
              <img
                alt=""
                role="presentation"
                src="@/assets/images/recircle-icon.png"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              If you need to change the scope of an approved grant
            </p>
            <p>
              If there is any change to an approved grant, you must inform us
              before the start date of the program or event. Examples of such
              changes are:
            </p>
            <ul>
              <li>Start or end date</li>
              <li>Venue or location</li>
              <li>
                Program details, objectives, agenda, or activities (such as live
                or virtual meetings or programs, website content, video content,
                social media content, podcast content, or newsletter content)
              </li>
              <li>Funding use or amount</li>
              <li>Any change impacting the executed LOA</li>
            </ul>
            <p>
              Please complete a
              <a
                class="content-link"
                href="/forms/USPA-Change-of-Scope-Request-Form.docx"
                target="_blank"
                >Change of Scope Form</a
              >
              and send it to your Merck USPA contact. We will consider the
              proposed changes and let you know if we approve or decline the
              change of scope request. Approved changes may require a revised
              LOA. Declined requests may require a return of grant funds.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <div class="icon-bx d-md-flex">
              <img
                alt=""
                role="presentation"
                src="@/assets/images/check-circle-icon.png"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              After your grant activity is done you are required to complete an
              outcomes form and reconciliation form for patient advocacy grants.
              Please note that outcomes are not required for fundraising
              charitable grants.
            </p>
            <p>
              <strong
                >Outcomes: Report the outcomes of your program or event</strong
              >
            </p>
            <p>
              Outcomes give important information (such as attendance numbers,
              change in knowledge, testimonials, and success tactics) that help
              us understand the impact and effectiveness of the programs we
              support. It is a chance for your organization to share the success
              of your program. It also verifies that the funding was used as
              described in the approved request.
            </p>
            <p>
              You must complete an outcomes form for a program that was funded
              with a Patient Advocacy Grant (it is not required for Fundraising
              Charitable Grants). According to the executed LOA, if you do not
              complete the required outcomes form, it may affect your
              organization’s eligibility for future grant support.
            </p>
            <ol>
              <li>
                Once your program is done, you will get an email from our Grant
                Portal prompting you to complete the outcomes form.
              </li>
              <li>
                You must complete the outcomes form in 45 calendar days.
                <p class="pt-3">
                  If you need more time, please inform your Merck USPA contact.
                  We will assess your extension request and give the decision to
                  the person who requested it.
                </p>
              </li>
            </ol>

            <p>
              <strong
                >Reconciliation: Report any grant funding you did not
                use</strong
              >
            </p>
            <p>
              You must complete a reconciliation form for a program that was
              funded with a Patient Advocacy Grant (it is not required for
              Fundraising Charitable Grants). According to the executed LOA, if
              you do not complete the required reconciliation form, it may
              affect your organization’s eligibility for future grant support.
            </p>
            <ol>
              <li>
                Once your program is done, you will get an email from our Grant
                Portal prompting you to complete the reconciliation form.
              </li>
              <li>
                The reconciliation form must be completed within 45 calendar
                days. In it, you will confirm that:
                <ul>
                  <li>
                    The grant funds were used in agreement with the LOA and not
                    used for any restricted purpose
                  </li>
                  <li>
                    The program happened and that the entire grant amount was
                    used in the manner and for the purpose it was intended, only
                    for the program specified in the LOA
                  </li>
                  <p class="mt-3">
                    If you find that you need more than 45 calendar days to
                    complete the form, please inform your Merck USPA contact. We
                    will assess your extension request and give the decision to
                    the person who requested it.
                  </p>
                </ul>
              </li>

              <li>
                You must return any unused funds by sending a check to the
                address on the reconciliation form.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <!--END CONTAINER ICONS-->
      <!--START CONTAINER-->
      <div class="container-md py-5 my-3">
        <HelpfulLinks />
      </div>
      <!--END CONTAINER-->
    </main>
    <LeavingModal />
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import Footer from '@/components/Footer.vue';
import HelpfulLinks from '@/components/HelpfulLinks.vue';
import LeavingModal from '@/components/LeavingModal.vue';

export default {
  name: 'grant-types',
  components: {
    HeaderNav,
    Footer,
    HelpfulLinks,
    LeavingModal,
  },
};
</script>
